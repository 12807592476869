import { Grid } from "./s25.virtual.grid.component";
import { S25Util } from "../../util/s25-util";

export namespace GridUtil {
    export function setHeaderMetadata<
        HeaderData extends Grid.CustomData,
        RowData extends Grid.CustomData,
        ItemData extends Grid.CustomData,
    >(data: Grid._Data<HeaderData, RowData, ItemData>) {
        data._columnDepth = S25Util.array.depth(
            data.headers,
            (h) => h.subHeaders,
            (h) => !h.hidden,
        );
        data._rowDepth = S25Util.array.depth(
            data.rows,
            (r) => r.subHeaders,
            (r) => !r.hidden,
        );

        for (const header of data.headers) setHeaderMetaData(header);
        for (const header of data.rows) setHeaderMetaData(header);

        data._columnData = GridUtil.getHeaderData(data.headers);
        data._rowData = GridUtil.getHeaderData(data.rows);
        data._columnCount = data._columnData.length;
        data._rowCount = data._rowData.length;

        const visibleColumns = S25Util.array.count(data._columnData, (header) => +!header.hidden);
        const visibleRows = S25Util.array.count(data._rowData, (header) => +!header.hidden);
        data._visibleColumnCount = visibleColumns;
        data._visibleRowCount = visibleRows;
    }

    export function setHeaderMetaData<HeaderData extends Grid.CustomData>(
        header: Grid._Header<HeaderData>,
        parent?: Grid._Header<HeaderData>,
    ) {
        if (!header._gridData) header._gridData = {};
        header._gridData.parent = parent;

        for (const subHeader of header.subHeaders ?? []) {
            setHeaderMetaData(subHeader, header);
        }
    }

    export function updateItemPosition<
        HeaderData extends Grid.CustomData,
        RowData extends Grid.CustomData,
        ItemData extends Grid.CustomData,
    >(data: Grid._Data<HeaderData, RowData, ItemData>, item: Grid._Item<ItemData>) {
        // Items are provided with a position in terms of width and height of the expanded grid where no columns or rows
        // are hidden. As such we need to calculate the position of each item after columns and rows have been hidden
        item._gridData.left = getPositionAfterHidingHeaders(item.left, data._columnData, data._visibleColumnCount);
        let right = truncateItemByHeader(item.left, item.left + item.width, data._columnData);
        right = getPositionAfterHidingHeaders(right, data._columnData, data._visibleColumnCount);
        item._gridData.width = right - item._gridData.left;

        item._gridData.top = getPositionAfterHidingHeaders(item.top, data._rowData, data._visibleRowCount);
        let bottom = truncateItemByHeader(item.top, item.top + item.height, data._rowData);
        bottom = getPositionAfterHidingHeaders(bottom, data._rowData, data._visibleRowCount);
        item._gridData.height = bottom - item._gridData.top;

        item._gridData.truncated = isItemTruncated(item, data._columnData, data._rowData);
    }

    export function getPositionAfterHidingHeaders<HeaderData extends Grid.CustomData>(
        percent: number,
        headerData: Grid.HeaderMeta<HeaderData>[],
        visibleHeaders: number,
    ) {
        const headers = headerData.length;
        const pos = (percent / 100) * headers; // Convert to header units
        let newPos = pos;
        newPos -= headerData[Math.floor(pos) - 1]?.hiddenSum || 0; // Subtract preceding hidden headers
        if (headerData[Math.floor(pos)]?.hidden) newPos = Math.floor(newPos); // Pos itself is hidden, align to header
        newPos = Math.round(newPos * headers * 100) / (headers * 100); // Align to a 100th of a header
        return (newPos / visibleHeaders) * 100; // Convert back to percent
    }

    export function getPositionBeforeHidingHeaders<HeaderData extends Grid.CustomData>(
        percent: number,
        headerData: Grid.HeaderMeta<HeaderData>[],
        visibleHeaders: number,
    ) {
        const pos = (percent / 100) * visibleHeaders; // Convert to header units
        const visibleHeaderIndex = Math.min(Math.floor(pos), headerData.length - 1);
        const headerIndex = headerData.findIndex((header, i) => i - header.hiddenSum === visibleHeaderIndex);
        const newPos = headerIndex + (percent === 100 ? 1 : pos % 1); // Translate to correct header
        return (newPos / headerData.length) * 100; // Convert back to percent
    }

    export function truncateItemByHeader<HeaderData extends Grid.CustomData>(
        start: number,
        end: number,
        headerData: Grid.HeaderMeta<HeaderData>[],
    ) {
        // If a header is marked with "truncateOverflow" we need to truncate any item which overflows the header
        // If two successive headers have different truncateRef, truncate and return
        let startCol = Math.floor((S25Util.clamp(start, 0, 100) / 100) * headerData.length);
        startCol = S25Util.clamp(startCol, 0, headerData.length - 1);
        const endCol = (S25Util.clamp(end, 0, 100) / 100) * headerData.length;
        let truncateRef = headerData[startCol].truncateRef;
        for (let i = startCol; i < endCol; i++) {
            if (headerData[i].truncateRef !== truncateRef) {
                // Truncate to start of header
                return (i / headerData.length) * 100;
            }
            truncateRef = headerData[i].truncateRef;
        }

        return end;
    }

    export function isItemTruncated<ItemData extends Grid.CustomData, HeaderData extends Grid.CustomData>(
        item: Grid._Item<ItemData>,
        columnData: Grid.HeaderMeta<HeaderData>[],
        rowData: Grid.HeaderMeta<HeaderData>[],
    ) {
        let firstCol = Math.floor((item.left / 100) * columnData.length); // Convert to column units
        firstCol = S25Util.clamp(firstCol, 0, columnData.length - 1);
        const lastCol = ((item.left + item.width) / 100) * columnData.length; // Convert to column units
        let truncateRef = columnData[firstCol].truncateRef;
        for (let i = firstCol; i < lastCol; i++) {
            if (columnData[i]?.truncateRef !== truncateRef) return true; // Truncated by "trucateOverflow" on header
            if (columnData[i]?.hidden) return true; // Truncated by hidden header
        }

        const firstRow = Math.floor((item.top / 100) * rowData.length); // Convert to row units
        const lastRow = ((item.top + item.height) / 100) * rowData.length; // Convert to row units
        truncateRef = rowData[firstRow].truncateRef;
        for (let i = firstRow; i < lastRow; i++) {
            if (rowData[i]?.truncateRef !== truncateRef) return true; // Truncated by "trucateOverflow" on header
            if (rowData[i]?.hidden) return true; // Truncated by hidden header
        }

        return false;
    }

    export function getHeaderData(headers: Grid.Header<Grid.CustomData>[]) {
        const data = getHeaderDataDFS(headers);

        // Find last visible header
        for (let i = data.length - 1; i >= 0; i--) {
            if (!data[i].hidden) {
                data[i].header._gridData.last = true;
                break;
            }
        }

        return data;
    }

    function getHeaderDataDFS<HeaderData extends Grid.CustomData>(
        headers: Grid.Header<Grid.CustomData>[],
        hiddenParent = false,
        truncateRef: Grid.Header<Grid.CustomData> = null,
        data: Grid.HeaderMeta<HeaderData>[] = [],
    ): Grid.HeaderMeta<HeaderData>[] {
        for (let header of headers) {
            if (header.subHeaders) {
                getHeaderDataDFS(
                    header.subHeaders,
                    hiddenParent || header.hidden,
                    header.truncateOverflow ? header : truncateRef,
                    data,
                );
            } else {
                const hidden = hiddenParent || header.hidden;
                const hiddenSum = (data.at(-1)?.hiddenSum || 0) + +!!hidden;
                data.push({ hidden, hiddenSum, truncateRef: header.truncateOverflow ? header : truncateRef, header });
            }
        }
        return data;
    }

    export function getMaxDragOffset(elem: HTMLElement) {
        const itemElement = elem.closest(".grid--item") as HTMLElement;
        const gridElement = itemElement.closest(".grid--area") as HTMLElement;

        return {
            top: -itemElement.offsetTop,
            bottom: gridElement.offsetHeight - itemElement.offsetTop - itemElement.offsetHeight,
            left: -itemElement.offsetLeft,
            right: gridElement.offsetWidth - itemElement.offsetLeft - itemElement.offsetWidth,
        };
    }

    export function doItemsOverlap(A: Grid.Item<Grid.CustomData>[], B: Grid.Item<Grid.CustomData>[]) {
        // Use left and width percentages to determine whether there is overlap
        for (let a of A) {
            for (let b of B) {
                const horizontal = a.left < b.left + b.width && a.left + a.width > b.left;
                if (!horizontal) continue;
                const vertical = a.top < b.top + b.height && a.top + a.height > b.top;
                if (vertical) return true;
            }
        }
        return false;
    }

    export function positionToHeaders<HeaderData extends Grid.CustomData>(
        offset: number,
        size: number,
        snap: number,
        headerData: Grid.HeaderMeta<HeaderData>[],
    ) {
        const err = snap / 100;
        const start = Math.round(((offset / 100) * headerData.length) / err) * err;
        const end = Math.round((((offset + size) / 100) * headerData.length) / err) * err;
        const headers: Grid.Header<HeaderData>[] = [];
        for (let i = Math.floor(start); i < end; i++) {
            headers.push(headerData[i].header);
        }

        return headers;
    }

    export function getCreatePosition<
        ColumnData extends Grid.CustomData,
        RowData extends Grid.CustomData,
        ItemData extends Grid.CustomData,
    >(
        creating: Grid.Creating,
        grid: Grid._Data<ColumnData, RowData, ItemData>,
        gridArea: HTMLElement,
    ): Grid.CreateData {
        if (!creating) return;

        const { initX, initY, floatX, floatY } = creating;
        const { _rowData, _visibleRowCount, _columnData, _visibleColumnCount } = grid;
        const rowHeight = gridArea.offsetHeight / _visibleRowCount;
        const columnWidth = gridArea.offsetWidth / _visibleColumnCount;

        // Account for flip when dragging forwards vs backwards
        const x = floatX < initX ? initX + columnWidth : initX;
        const y = floatY < initY ? initY + rowHeight : initY;

        // Position in DOM
        const visualTop = (Math.min(y, floatY) / gridArea.offsetHeight) * 100;
        const visualBottom = (Math.max(y, floatY) / gridArea.offsetHeight) * 100;
        const visualLeft = (Math.min(x, floatX) / gridArea.offsetWidth) * 100;
        const visualRight = (Math.max(x, floatX) / gridArea.offsetWidth) * 100;

        // Position after accounting for hidden rows/columns
        const top = GridUtil.getPositionBeforeHidingHeaders(visualTop, _rowData, _visibleRowCount);
        const bottom = GridUtil.getPositionBeforeHidingHeaders(visualBottom, _rowData, _visibleRowCount);
        const left = GridUtil.getPositionBeforeHidingHeaders(visualLeft, _columnData, _visibleColumnCount);
        const right = GridUtil.getPositionBeforeHidingHeaders(visualRight, _columnData, _visibleColumnCount);

        return { top, height: bottom - top, left, width: right - left };
    }
}

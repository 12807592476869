import { Grid } from "./s25.virtual.grid.component";
import { S25Util } from "../../util/s25-util";
import { DowGrid } from "./s25.dow.grid.component";

export namespace DowGridUtil {
    export function getItemPosition(
        itemData: { startHour: number; endHour: number },
        totalColumns: number,
        index: number,
    ) {
        if (!totalColumns) return { left: 0, width: 0 };
        // DOW index + hour index = column index
        const left = ((index + itemData.startHour) / totalColumns) * 100;
        let hours = itemData.endHour - itemData.startHour;
        if (hours < 0) hours += 24; // Item spans midnight
        const width = (hours / totalColumns) * 100;
        return { left, width };
    }

    export function getHeaders<HeaderData extends Grid.CustomData>(
        visibleDows: string[],
        dows: string[],
        startHour: number,
        endHour: number,
        is24Hours: boolean,
    ): Grid.Header<HeaderData>[] {
        const headers: Grid.Header<HeaderData>[] = [];
        const visible = new Set(visibleDows);
        for (let dow of dows) {
            const dowHeader: Grid.Header<HeaderData> = {
                id: dow,
                heading: dow,
                data: {} as HeaderData,
                subHeaders: [],
                hidden: !visible.has(dow),
                truncateOverflow: true,
            };
            for (let hour = 0; hour < 24; hour++) {
                const start = S25Util.date.toTimeStrFromHours(hour, is24Hours);
                dowHeader.subHeaders.push({
                    id: `${dow}-${hour}`,
                    heading: `${start}`,
                    data: {} as HeaderData,
                    hidden: hour < startHour || hour >= endHour,
                });
            }
            headers.push(dowHeader);
        }

        return headers;
    }

    export function getTimesFromPosition(item: { left: number; width: number }, dows: string[]) {
        const columnCount = 24 * dows.length;

        // Figure out new dow, based on left
        const column = (item.left / 100) * columnCount;
        const dowIndex = S25Util.clamp(Math.floor(column / 24), 0, dows.length - 1);
        const dow = dows[dowIndex];
        // Figure out new start time, based on left and dow
        const startHour = column % 24;
        // Figure out new end time, based on start and width
        const endHour = (startHour + (item.width / 100) * columnCount) % 24;

        return { dow, startHour, endHour };
    }
}
